import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./sort");
  require("./scrolltop");
  require("./pop-up");
  require("./parallax");
  require("./leaflet.markercluster");
  require("./leaflet.featuregroup.subgroup");
  // require("./leafler-groupedlayercontrol");
  // require("./styledLayerControl");
  // require("./leafletmarkercluster");
  // require("./leafletmarkerclusterlayersupport");
  require("./faq");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  let text = [];

  $('.readmore__button').on('click', function(){

    let index = $(this).index($('.readmore__button'));

    if(!text[index]) {
      text[index] = $(this).html();
    }

    if($(this).hasClass('readmore__button_less')) {
      $(this).removeClass('readmore__button_less').html(text[index])
      .siblings('.readmore__more').hide('fast');
    } else {
      $(this).addClass('readmore__button_less').html('Ukryj')
        .siblings('.readmore__more').show('fast');
    }

  });

  $(document).ready(function () {
    // Move current language from dropdown list
    $( ".lang-switcher" ).each(function() {
      var list = $(this).find( ".list .current" );
      $(list).appendTo($(this).find('.selected'));
    });
  
    // Show / Hide  list of languages
    $(".lang-switcher").on('click', function (event) {
      $(this).find('.list').fadeToggle('fast');
      $(this).toggleClass('lang-switcher--opened');
    });
  });
  
  // Close when clicking outside
  $(document).bind('click', function(e){
    if (! $(e.target).parents().hasClass("lang-switcher")) {
      $(".lang-switcher .list").fadeOut();
      $(".lang-switcher").removeClass('lang-switcher--opened');
    }
  });
  $(".v-walk__box").on('click', function () {
    $(".v-walk__box iframe").attr("src","/virtual/index.html");
    $(".v-walk__box .bottom").fadeOut();
    $(".v-walk__box img").fadeOut("slow");
    $(".v-walk__box").css('background-image', 'unset');
    $(".v-walk__box iframe").fadeIn();
  });

  $(".places__types-single").on('click', function () {
    var place = $(this).attr('data-place');
    $(".places__types-single").removeClass('active');
    $(".places__desc-single").removeClass('active');
    $(this).addClass('active');
    $(".places__desc-single[data-place="+ place +"]").addClass('active');
  });

  $('.house-widget').on('click mouseover', function () {
    let n = $(this).data('n');
    $('.house-widget').removeClass('active');
    $('.house-widget#m' + n).addClass('active');

    $('.flat-list__list .flat-list__item').removeClass('active');
    $('.flat-list__list .flat-list__item#l' + n).addClass('active');
  });

  $('.house-widget').on('click', function () {
    let n = $(this).data('n');
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.flat-list__list .flat-list__item#l' + n).offset().top - 150
    }, 500);
  });

  $('.flat-list__list .flat-list__item').on('click', function () {
    let n = $(this).data('n');
    $('.flat-list__list .flat-list__item').removeClass('active');
    $(this).addClass('active');

    $('.house-widget').removeClass('active');
    $('.house-widget#m' + n).addClass('active');
  });

  $('.flat-list__list .flat-list__item .click').on('click', function () {
    let n = $(this).parent().data('n');
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.house-widget#m' + n).offset().top - 150
    }, 500);
  });

  $('.flat-list__list .flat-list__item .ask').on('click', function () {
    let n = $(this).parent().data('n');
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.footer .form').offset().top 
    }, 500);
    $('.footer .form textarea').addClass('form__input--has-content');
    $('.footer .form textarea').val('Zapytanie o ofertę numer: ' + n + '\n\n');

  
  });


  var $tooltip = $(".flat-widget__tooltip");

  $('.house-widget').hover(function () {
    $tooltip.addClass('active');
    $tooltip.html($(this).attr('title'));
  }, function () {
    $tooltip.removeClass('active');
  });

  $(document).on('mousemove', function (e) {
    $tooltip.css({
      left: e.pageX,
      top: e.pageY - 70
    });
  });

});
